@font-face {
   font-family: "Open-Dyslexic";
   src: url("/assets/fonts/OpenDyslexic-Regular.otf");
 }
 
 .grayscale {
   filter: grayscale(1);
 }
 
 .big-cursor {
   cursor: url("/assets/images/big-cursor.png"), auto;
 }
 
 .reader-cursor-pointer {
   cursor: pointer !important;
 }
 
 .invert-contrast {
   filter: invert(1);
 }
 
 .font-dyslexic {
   font-family: Open-Dyslexic, serif !important;
 }
 
 .highlight-links:link {
   background-color: #ff0;
   font-weight: bold;
   color: black;
 }