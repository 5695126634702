/* clases para no mostrar elementos en base al breakpoint de bootstrap */
@media (max-width: 767.98px) { 
   .col-xs-0{
     display:none;
   }
 }
 
 @media (min-width: 768px) and (max-width: 991.98px) { 
   .col-sm-0{
       display:none;
   }
 }
 
 @media (min-width: 992px) and (max-width: 1199.98px) { 
   .col-md-0{
       display:none;
   }
 }
 
 @media (min-width: 1200px) { 
   .col-lg-0{
     display:none;
   }
 }